import React, { Component } from 'react';
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components

export class Footer extends Component  {
  state = { }

  getYear() {
    return new Date().getFullYear();
  }

  componentDidMount() {
    // const getMobileMenu = axios.get('https://www.usa.edu/wp-json/wp-api-menus/v2/menus/758');

    // Promise.all([getMobileMenu]).then(res => {
    //   console.dir(res);
    //   this.setState({
    //     mobileMenu: res[0].data.items
    //   });
    // });
  }

  topOfContent() {
    let form = document.querySelector('#main');
    const y = form.offsetTop - 25;

    window.scrollTo({
      top: y,
      behavior: 'smooth'
    })
  }

  render() {
    // const { mobileMenu } = this.state;
    // console.dir(this.state);

    return (
      <>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-6 col-offset-3">

                <p>Copyright &copy; { this.getYear() }. <a href="https://www.usa.edu">University of St. Augustine for Health Sciences</a></p>
                <span className="logo"></span>

              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;