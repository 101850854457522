import React, { Component } from 'react';
import QRCode from 'react-qr-code';
// import axios from 'axios';

class Page extends Component {
  state = { vac: null, uid: null };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.querySelectorAll('header p').forEach((e) => {
      e.previousElementSibling.style.marginBottom = '0';
      e.classList.add('hidden');
    });

    document.querySelector('.form').classList.add('submitted');
  }

  render() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let mm = today.getMonth();
    let yyyy = today.getFullYear();
    today = month[mm] + ' ' + dd + ', ' + yyyy;

    return (
      <>
        <div className="form">
          <div className="container">
            <div className="row">
              <div className="col-6 col-offset-3">

                <h2><i className="fa fa-check-circle"></i> Thank You.</h2>
                <p>Thank you for submitting your daily health screening form for today, <strong className="cleared">{ today }</strong>.</p>

                <p>Based on your health screening results for today, you are <strong className="cleared">cleared to come to campus</strong>.</p>

                <p>Please bring the following BarCode as a printed copy or display it on your mobile device. Arrive on campus at your scheduled time, and proceed directly to the designated campus entry point. You will be asked to show your BarCode to scan for entry. You will receive a wristband for today, which you should wear at all times while on campus. When leaving campus, please discard the wristband. You will receive a new wristband for each day.</p>

                <div className="qrcode">
                  <QRCode value={ this.props.qrcode } fgColor="#005164" />
                </div>

                {/* <p className="notice"><em>Three-ply, surgical/procedural face masks, or N95 / KN95 masks, are required to be worn by <b>all students, employees and visitors while present in classrooms, labs, pro bono clinics, the Center for Innovative Clinical Practice (CICP)</b> and during any <b>activities with patients</b>. This policy is effective regardless of the number of people in these academic campus rooms or locations. In <b>all other campus areas</b> (including hallways, offices/office areas, libraries, lounges and study spaces), masks will be <b>optional</b> for all students, faculty and staff.</em></p> */}

                {/* <p className="notice"><em>The University of St. Augustine for Health Sciences maintains its health and safety protocols, including quarantine periods, cleaning procedures, and vaccine and testing options.</em></p> */}

                <p>You will also be receiving a confirmation email with this barcode and further instructions from <a href="mailto:reentry@usa.edu">reentry@usa.edu</a>.  If you do not receive the email, please contact <a href="mailto:reentry@usa.edu">reentry@usa.edu</a> or call <strong>1-855-GOUSAHS (1-855-468-7247)</strong>.</p>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Page;