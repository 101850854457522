import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import OktaSignIn from './OktaSignInWidget';
import { withOktaAuth } from '@okta/okta-react';
import Cookies from 'universal-cookie';

import Protected from './pages/Main';
import Cleared from './pages/Cleared';
import NotCleared from './pages/NotCleared';
// import Home from './Home';

export default withOktaAuth(class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { e: null, qr: null }
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const user = new Cookies();
    this.setState({ e: user.get('usahsReentry-email') });
    this.setState({ a: user.get('usahsReentry-access') });
    this.setState({ qr: user.get('usahsReentry-qrcode') });
  }

  onSuccess(res) {
    const email = res.user.profile.login;
    const name = res.user.profile.firstName + ' ' + res.user.profile.lastName;

    const midnight = new Date();
    midnight.setHours(23,59,59,0);
    midnight.setDate(midnight.getDate()+ 7);

    const cookies = new Cookies();
    cookies.set('usahsReentry-email', email, { path: '/', expires: midnight });
    cookies.set('usahsReentry-user', name, { path: '/', expires: midnight });

    if (res.status === 'SUCCESS') {
      return this.props.authService.redirect({
        sessionToken: res.session.token
      });
    } else {
    // The user can be in another authentication state that requires further action.
    // For more information about these states, see:
    //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
    }
  }

  onError(err) {
    console.log('error logging in', err);
  }

  render() {
    let { qr } = this.state;
    // console.dir(this.state);

    return (this.state.e) ?
      (this.state.a) ?
        (this.state.a === 'passed') ?
          <Cleared data={ this.state } qrcode={ qr } /> :
          <NotCleared data={ this.state } qrcode={ qr } reset="false" /> :
        <Protected /> :
        <OktaSignIn
          baseUrl={this.props.baseUrl}
          onSuccess={this.onSuccess}
          onError={this.onError} />;
  }
});

