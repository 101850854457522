import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

// components

class Page extends Component {
  constructor(props) {
    super(props);
    let errors = {
      questions: true,
      legal: true,
      nodoc: true,
      upload: false,
      boosterDate: false,
      vaxDate: false,
      testDate: false,
      oldTest: false,
      oldVax: false,
    }
    this.state = { preloader: false, isLoaded: false, docInfo: [], notificationInfo: [], uploadInfo: [], lastq: 5, qrcode: props.qrcode, errorCodes: errors, submitReady: false, doubleVerify: null };
    this.reentryapi = process.env.REACT_APP_REENTRY_API;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let self = this;
    let uemail = this.props.data.email;
    let uname = this.props.data.fname;
    // console.dir(this.props.data);

    let UI = {
      fname: uname.split(' ')[0],
      lname: uname.split(' ')[1],
      email: uemail
    }

    this.setState({ userInfo: UI });

    const inputs = document.querySelectorAll('.form-input');

    inputs.forEach((i) => {
      i.addEventListener('change', function() {
        let updateErrror = this.state.errorCodes;

        if(i.id === 'legal' && i.checked === true) {
          updateErrror.legal = false;
          this.setState({ errorCodes: updateErrror });
        }

        if(i.id === 'legal' && i.checked === false) {
          updateErrror.legal = true;
          this.setState({ errorCodes: updateErrror });
        }

        this.validateForm();
      }.bind(this))
    });

    // Deal with userinfo coming from database
    const getUser = axios.get(this.reentryapi+'/vax/show/' + uemail);
    axios.all([getUser])
    .then(function (response) {
      // console.dir(response);
      const u = response[0].data[0];
      // console.dir(u);

      // set all the userinfo
      let userInfo = {
        fname: u.first_name,
        lname: u.last_name,
        email: u.email,
        uid: u.id
      }

      // dates for document validity
      let checkDate = (u.docDate) ? (u.docDate === '0000-00-00 00:00:00') ? '2021-09-14' : u.docDate : '2021-09-14';
      // console.dir(checkDate);
      let today = moment().format('YYYY-MM-DD');
      let docDateSevenDays = moment(checkDate).add(7,'d').format('YYYY-MM-DD');
      let docDateFiveMonths = moment(checkDate).add(5,'M').format('YYYY-MM-DD');

      // can be removed after launch
      let launch = moment('2022-02-14');
      if (moment(docDateFiveMonths).isBefore(launch, 'day')) {
        docDateFiveMonths = launch;
      }

      let docStatus = null, docExpireDate = null, diff = null, docTypeFull = null;
      if (u.docType === null) {
        docStatus = 'none';
        docTypeFull = 'No Document';

      } else if (u.docType === 'negTest') {
        docTypeFull = 'Negative Covid Test';
        if (moment().isBefore(docDateSevenDays, 'day') || moment(today).isSame(docDateSevenDays, 'day')) {
          docStatus = 'valid';
          docExpireDate = docDateSevenDays;
          console.dir(today);
          console.dir(docExpireDate);
          diff = moment(docExpireDate).diff(moment(today), 'days');

          // document.querySelector('#error_nodoc').style.display='none';
          let updateErrror = self.state.errorCodes;
          updateErrror.nodoc = false;
          self.setState({ errorCodes: updateErrror });
        } else {
          docStatus = 'expired';
          docExpireDate = docDateSevenDays;
        }

      } else if (u.docType === 'vaccine') {
        docTypeFull = 'COVID-19 Vaccine Record';
        if (moment().isBefore(docDateFiveMonths, 'day')) {
        // if (moment().isBefore(docDateFiveMonths, 'day') || moment(today).isSame(docDateFiveMonths, 'day')) {
          docStatus = 'valid';
          docExpireDate = docDateFiveMonths;

          // document.querySelector('#error_nodoc').style.display='none';
          let updateErrror = self.state.errorCodes;
          updateErrror.nodoc = false;
          self.setState({ errorCodes: updateErrror });
        } else {
          docStatus = 'expired';
          docExpireDate = docDateFiveMonths;
        }

      } else {
        docTypeFull = 'COVID-19 Vaccine & Booster Records';
        docStatus = 'valid';

        // document.querySelector('#error_nodoc').style.display='none';
        let updateErrror = self.state.errorCodes;
        updateErrror.nodoc = false;
        self.setState({ errorCodes: updateErrror });
        self.state.lastq = 4;
      }

      let notificationInfo = {
        date: u.docDate,
        dateFormatted: moment(u.docDate).format('dddd, MMM DD YYYY'),
        expireDate: docExpireDate,
        expireDateFormatted: moment(docExpireDate).format('dddd, MMM DD YYYY'),
        diff: diff,
        type: u.docType,
        typeFull: docTypeFull,
        url: u.docUrl,
        status: docStatus
      }

      let docInfo = {
        type: u.docType,
        date: u.docDate,
        typeFull: docTypeFull,
        status: docStatus
      }

      // set all the data to the State
      self.setState({
        userInfo: userInfo,
        notificationInfo: notificationInfo,
        docInfo: docInfo,
        isLoaded: true
      });

    })
    .catch(function (error) {
      // if user is not in the database, we need to create one
      // console.dir(error.toJSON());
      if (error.request.responseURL.includes('/vax/show')) {
        console.log('no user');

        axios.post(self.reentryapi+'/api/adduser', {
          fname: uname.split(' ')[0],
          lname: uname.split(' ')[1],
          email: uemail
        })
        .then(function (response) {
          console.log(response);
          self.setState({ uid: response.data.id });
        })
        .catch(function (error) {
          console.log(error);
        });
      };
    });

    const radios = document.querySelectorAll('.form-check');
    // const checkboxes = document.querySelectorAll('.form-checkboxes');
    // const dateInput = document.querySelector('.DayPickerInput input');

    radios.forEach((e) => {
      e.addEventListener('click', this.handleRadioButton, true);
    });

    // checkboxes.forEach((e) => {
    //   e.addEventListener('click', this.handleCheckbox, true);
    // });
  }

  async componentDidUpdate() {
    // this.validateForm();
  }

  handleRadioButton = (e) => {
    let el = e.target;
    el.parentElement.firstChild.classList.remove('checked');
    el.parentElement.lastChild.classList.remove('checked');

    el.classList.toggle('checked');
    el.firstElementChild.checked = true;

    const q = el.firstElementChild.name;
    const a = el.firstElementChild.value;

    switch(q) {
      case 'question1':
        this.setState({ question1 : a }); break;
      case 'question2':
        this.setState({ question2 : a }); break;
      case 'question3':
        this.setState({ question3 : a }); break;
      case 'question4':
        this.setState({ question4 : a }); break;
      default:
        return '';
    }

    if (this.state.question1 && this.state.question2 && this.state.question3) {
      let updateErrror = this.state.errorCodes;
      updateErrror.questions = false;
      this.setState({ errorCodes: updateErrror, allquestions: 'valid' });
    }
    this.validateForm();
  }

  // pulls info from the upload component when needed
  handleUpload = (arg) => {
    this.setState({ uploadInfo: arg });
  }

  handleUploadErrors = (arg) => {
    // console.dir(arg);
    let updateErrror = this.state.errorCodes;
    updateErrror.nodoc = arg.nodoc;
    updateErrror.boosterDate = arg.boosterDate;
    updateErrror.oldTest = arg.oldTest;
    updateErrror.oldVax = arg.oldVax;
    updateErrror.testDate = arg.testDate;
    updateErrror.upload = arg.upload;
    updateErrror.vaxDate = arg.vaxDate;
    this.setState({ errorCodes: updateErrror });
    this.validateForm();
  }

  validateForm = (e) => {
    console.log('validating.....');
    let codes = this.state.errorCodes;
    let valid = this.state.submitReady;
    // console.dir(codes);

    if (codes.questions || codes.legal) {
      document.querySelector('button#verify').disabled = true;
      this.setState({ submitReady: false });
    } else {
      // console.log(this.state);
      document.querySelector('button#verify').disabled = false;
      this.setState({ submitReady: true });
    }
    console.log(valid);
  }

  dVerify = (e) => {
    console.dir('VERIFYING');
    e.preventDefault();

    window.scrollTo(0, 0);
    document.querySelector('body').style.overflow = 'hidden';

    this.setState({ doubleVerify: true });
  }

  submitForm = (e) => {
    e.preventDefault();
    let { submitReady } = this.state;
    this.setState({ doubleVerify: false });
    document.querySelector('body').style.overflow = '';

    // turn on loader
    if (submitReady) {
      this.setState({ preloader: true });
      document.querySelector('html').classList.add('noscroll');

      let self = this;
      console.dir('========== SUBMISSION ==========');
      let redirect = (this.state.question1 === 'Yes' || this.state.question2 === 'Yes' || this.state.question3 === 'Yes' || this.state.question4 === 'Yes')? 'failed' : 'passed';

      this.setState({ redirect: redirect });

      // document data needed here
      // if (this.state.docStatus !== 'valid' && this.state.uploadInfo.filename) {
      if (this.state.uploadInfo.length === undefined && (this.state.uploadInfo.filename !== null || this.state.uploadInfo.filename !== undefined)) {
      // if (self.state.uploadInfo.length > 0) {
        console.dir('==== SUBMIT FILE')
        axios.post(this.reentryapi+'/vax/update/' + this.state.userInfo.uid, {
          docType: self.state.uploadInfo.type,
          docDate: self.state.uploadInfo.date,
          docUrl: self.state.uploadInfo.filename
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          // console.log(error);
        });

        axios.post(this.reentryapi+'/document/create/', {
          uid: self.state.userInfo.uid,
          docType: self.state.uploadInfo.type,
          docDate: self.state.uploadInfo.date,
          docUrl: self.state.uploadInfo.filename
        })
        .then(function (response) {
          console.log(response);
          // this.setState({ docid: response.data.id });
        })
        .catch(function (error) {
          // console.log(error);
        });
      }

      let formdata = {
        fname: this.state.userInfo.fname,
        lname: this.state.userInfo.lname,
        email: this.state.userInfo.email,
        status: redirect,
        system: 'okta',
        qrcode: this.state.qrcode,
        q1: (this.state.question1 === 'Yes') ? 1 : 0,
        q2: (this.state.question2 === 'Yes') ? 1 : 0,
        q3: (this.state.question3 === 'Yes') ? 1 : 0,
        q4: 0
      };

      console.dir(formdata);

      axios.post(this.reentryapi+'/form/create', formdata)
      .then(function (response) {
        console.log(response);
        self.state.lastsub = response;
        self.props.handleToUpdate(redirect);
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      console.log('Not all fields entered. Please check errors for missing data.');
    }

    console.dir('========== END SUBMISSION ==========');
  }

  render() {
    let { preloader, userInfo, doubleVerify, errorCodes } = this.state;
    // console.dir(this.state);

    return (
      <>
        <div className="form">
          <div className="container">
            <div className="row">
              <div className="col-8 col-offset-2">

                {userInfo && (
                  <p>Hi <strong>{ userInfo.fname }</strong>, please answer the questions below so we can verify if you are cleared to come on to campus today.</p>
                )}

                <form onSubmit={ this.dVerify }>
                  {/* Question 1 */}
                  <span className="question">
                    <label htmlFor="question1"><span>1.</span> Do you have one or more symptoms that are new or unexplained (i.e., rash/lesion, fever, chills, cough, sore throat, shortness of breath, muscle pain, headache, loss of taste or smell, fatigue, congestion or runny nose, nausea or vomiting, or diarrhea)? <small>*</small></label>

                    <div className="options">
                      <div className="form-check form-check-inline -yes">
                        <input className="form-check-input" type="radio" name="question1" id="q1-yes" value="Yes" />
                        <label className="form-check-label" htmlFor="q1-yes">Yes</label>
                      </div>
                      <div className="form-check form-check-inline -no">
                        <input className="form-check-input" type="radio" name="question1" id="q1-no" value="No" />
                        <label className="form-check-label" htmlFor="q1-no">No</label>
                      </div>
                    </div>
                  </span>

                  {/* Question 2 */}
                  <span className="question">
                    <label htmlFor="question2"><span>2.</span> In the last 5 days, have one or more of the following applied to you: <small>*</small>
                      <ul>
                        <li>Tested positive for the COVID-19 virus or monkeypox?</li>
                        <li>Experienced symptoms related to <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html" target="_blank" rel="noopener noreferrer">COVID-19</a> or <a href="https://www.cdc.gov/poxvirus/monkeypox/symptoms/index.html" target="_blank" rel="noopener noreferrer">monkeypox</a>?</li>
                        <li>Received direction from a health care provider to self-quarantine for concerns related to COVID-19 or monkeypox?</li>
                      </ul>
                    </label>

                    <div className="options">
                      <div className="form-check form-check-inline -yes">
                        <input className="form-check-input" type="radio" name="question2" id="q2-yes" value="Yes" />
                        <label className="form-check-label" htmlFor="q2-yes">Yes</label>
                      </div>
                      <div className="form-check form-check-inline -no">
                        <input className="form-check-input" type="radio" name="question2" id="q2-no" value="No" />
                        <label className="form-check-label" htmlFor="q2-no">No</label>
                      </div>
                    </div>
                  </span>

                  {/* Question 3 */}
                  <span className="question">
                    <label htmlFor="question3"><span>3.</span> Have you been recently tested for COVID-19 or monkeypox due to symptoms and are waiting for the results? <small>*</small>
                    </label>

                    <div className="options">
                      <div className="form-check form-check-inline -yes">
                        <input className="form-check-input" type="radio" name="question3" id="q3-yes" value="Yes" />
                        <label className="form-check-label" htmlFor="q3-yes">Yes</label>
                      </div>
                      <div className="form-check form-check-inline -no">
                        <input className="form-check-input" type="radio" name="question3" id="q3-no" value="No" />
                        <label className="form-check-label" htmlFor="q3-no">No</label>
                      </div>
                    </div>
                  </span>

                  {/* Question 4 */}
                  <span className="question">
                    <label htmlFor="question4"><span>4.</span> Within the last 21 days, does one or more of the following apply to anyone with whom you have had <a href="https://www.cdc.gov/poxvirus/monkeypox/if-sick/transmission.html" target="_blank" rel="noopener noreferrer">close contact</a>: <small>*</small>
                      <ul>
                        <li>Exhibited <a href="https://www.cdc.gov/poxvirus/monkeypox/symptoms/index.html" target="_blank" rel="noopener noreferrer">monkeypox symptoms</a>?</li>
                        <li>Received a diagnosis of confirmed or probable monkeypox?</li>
                      </ul>
                    </label>

                    <div className="options">
                      <div className="form-check form-check-inline -yes">
                        <input className="form-check-input" type="radio" name="question4" id="q4-yes" value="Yes" />
                        <label className="form-check-label" htmlFor="q4-yes">Yes</label>
                      </div>
                      <div className="form-check form-check-inline -no">
                        <input className="form-check-input" type="radio" name="question4" id="q4-no" value="No" />
                        <label className="form-check-label" htmlFor="q4-no">No</label>
                      </div>
                    </div>
                  </span>

                  {/* Question 6 */}
                  <span className="q5">
                    <label htmlFor="question6"><span>5.</span>
                      <input type="checkbox" className="form-input" id="legal" />
                      <label htmlFor="legal"></label>I agree to abide by USAHS rules related to campus re-entry to jointly ensure a safe and healthy campus environment. I understand misrepresentation of information is a health hazard and cause for possible dismissal, termination and/or disciplinary action.</label>

                    <p className="italic">If you answered <b>YES</b> to any of the 1-4 above do not come to campus.</p>
                  </span>

                  <div className="errors">
                    {errorCodes.questions && ( <p id="error_qs">Please answer questions 1-4.</p> )}
                    {errorCodes.nodoc && ( <p id="error_nodoc">Please choose an option for question 4.</p> )}
                    {errorCodes.upload && ( <p id="error_upload">Please upload your documentation.</p> )}
                    {errorCodes.vaxDate && ( <p id="error_vaxdate">Please provide a date of your full vaccination (2 weeks after final dose).</p> )}
                    {errorCodes.boosterDate && ( <p id="error_boosterdate">Please provide a date of your booster shot.</p> )}
                    {errorCodes.testDate && ( <p id="error_testdate">Please provide the date your specimen collected/test taken.</p> )}
                    {errorCodes.oldTest && ( <p id="error_oldtest">The date selected for you COVID test is more than 7 days old.</p> )}
                    {errorCodes.oldVax && ( <p id="error_oldvax">The date selected for you COVID vaccine is more than 5 months old. Please supply Booster or Negative Test that is less than 7 days old.</p> )}
                    {errorCodes.legal && ( <p id="error_leg">Please agree to question 5.</p> )}
                  </div>
                  <button id="verify" className="btn btn-primary" disabled>Submit</button>
                </form>

                {doubleVerify && (
                  <div className="dverifyCont">
                    <div className="inner">
                      <p>I certify that my responses are true and accurate to the best of my knowledge.</p>
                      <p>I understand that any misrepresentation is a violation of the Code of Conduct and can be the basis for dismissal, termination and/or disciplinary action. If you have any questions, please contact <a href="mailto:reentry@usa.edu">reentry@usa.edu</a> or call 855-GO USAHS. Employees not authorized to enter campus should notify <a href="mailto:reentry@usa.edu">reentry@usa.edu</a> and their supervisor. Students not authorized to enter campus should notify <a href="mailto:reentry@usa.edu">reentry@usa.edu</a> and their program director.</p>
                      <button onClick={ this.submitForm.bind(this) } id="formSubmit" className="btn btn-primary">Submit</button>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>

        {preloader && (
          <div className="preloader">
            <div className="container">
              <div className="row">
                <div className="col-6 col-offset-3">
                  <div className="loader"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Page;