import React, { Component } from 'react';

class Page extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  eTrain = (e) => {
    window.open('https://apps.powerapps.com/play/40c15c45-ba47-4cea-a9f8-19eeb53b02c8', '_blank');
  }

  sTrain = (e) => {
    window.open('https://apps.powerapps.com/play/51731cef-63f5-441a-8789-757eda5cbc35', '_blank');
  }

  render() {
    // let usaShield = require('/global/images/crest192.png');
    // console.dir(this.props);

    return (
      <>
        <div className="form submitted">
          <div className="container">
            <div className="row">
              <div className="col-6 col-offset-3">

                <h2><i className="fa fa-times-circle red"></i> Training Required.</h2>

                <p><strong className="cleared">Required Campus Reentry Training</strong> is intended to support safe and healthy on-campus practices.  This required training is online and includes short video modules with important information about COVID-19. Reentry Training takes approximately 25 minutes and covers the following topics:</p>

                <ul>
                  <li>COVID-19 Risk and Symptoms</li>
                  <li>COVID-19 Prevention</li>
                  <li>Personal Protective Equipment</li>
                  <li>Health Screening Requirements and USAHS Reporting Protocols</li>
                  <li>Updated USAHS Policies and Notifications Related to COVID-19 (for Employees)</li>
                </ul>

                <p>Upon completion of the training, you will be asked to electronically sign an acknowledgement confirming that you have completed all the modules and that you understand the safety and health protocols as well as the updated USAHS policies related to COVID-19.</p>

                <p>To complete the training, please choose the appropriate training by clicking below.</p>

                <div className="button-cont">
                  <button type="submit" className="btn btn-primary" onClick={ this.sTrain }>STUDENT TRAINING</button>
                </div>
                <div className="button-cont" >
                  <button type="submit" className="btn btn-primary" onClick={ this.eTrain }>FACULTY and STAFF TRAINING</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Page;