import React, { Component } from 'react';
import { withOktaAuth } from "@okta/okta-react";
import axios from 'axios';
import Cookies from 'universal-cookie';

// components
import Header from '../components/Header';
import Footer from '../components/Footer';
// page content
import Form from './Form';
import Cleared from './Cleared';
import NotCleared from './NotCleared';
import TrainingMessage from './TrainingMessage';

export default withOktaAuth(
  class Page extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoaded: false, email: null, training: null, lastsub: [], redirect: null, qrcode: this.qrcode(36),
      };
      this.reentryapi = process.env.REACT_APP_REENTRY_API;
    }

    componentDidMount() {
      const cookie = new Cookies();
      let uemail = cookie.get('usahsReentry-email');
      let uname = cookie.get('usahsReentry-user');
      let self = this;

      this.setState({ fname: uname });
      this.setState({ email: uemail });

      // Deal with info coming from database
      const trainingStatus = axios.get(this.reentryapi+'/api/training/' + uemail);
      const submissionStatus = axios.get(this.reentryapi+'/api/form/' + uemail);
      axios.all([trainingStatus, submissionStatus])
      .then(function (response) {
        const t = response[0].data;
        const s = response[1].data[0];

        // set training
        let istrained = (t.length > 0) ? '1' : '0';
        self.setState({ training: istrained });

        let now = new Date();
        let dd = String(now.getDate()).padStart(2, '0');
        let mm = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = now.getFullYear();
        let today = yyyy + '-' + mm + '-' + dd;

        // If the user has already done a submisison today.
        if (s && s.date.includes(today)) {
          self.state.qrcode = s.qrcode;
          self.state.lastsub.date = s.date;
          self.state.lastsub.id = s.id;

          const midnight = new Date();
          midnight.setHours(23,59,59,0);
          // const cookie = new Cookies();
          // cookie.set('usahsReentry-qrcode', s.qrcode, { path: '/', expires: midnight });

          self.setState({ redirect: s.access });
        }

        // set isLoaded so the page will load
        self.setState({ isLoaded: true });
      })
      .catch(function (error) {
        console.dir(error);
      });
    }

    qrcode = (length) => {
      let result = '';
      const cookie = new Cookies();

      if(cookie.get('usahsReentry-qrcode')) {
        result = cookie.get('usahsReentry-qrcode');
      } else {
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
      }

      return result;
    }

    // pulls information from form when needed
    handleSubmission = (arg) => {
      this.setState({ redirect: arg });
      document.querySelector('html').classList.remove('noscroll');
    }

    render() {
      let { isLoaded, redirect, qrcode, training } = this.state;
      // console.dir('redirect is ' + redirect);

      return (
        <>
          {isLoaded && ( <Header data={ this.state } /> )}

            {/* Return Training is not is database */}
            {(isLoaded && training === '0') && (
              <TrainingMessage />
            )}

            {/* Return Cleared if already submitted and clear */}
            {(isLoaded && redirect === 'passed') && (
              <Cleared data={ this.state } qrcode={ qrcode } />
            )}

            {/* Return NotCleared if already submitted and not clear */}
            {(isLoaded && redirect === 'failed') && (
              <NotCleared data={ this.state } qrcode={ qrcode } />
            )}

            {/* Return form for submission if trained and first attempt for the day */}
            {(isLoaded && training === '1' && redirect === null) && (
              <Form data={ this.state } qrcode={ qrcode } handleToUpdate={ this.handleSubmission.bind(this) } />
            )}

          {isLoaded && ( <Footer /> )}
        </>
      );
    }
  }
);