import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouterAccess from './AppRouterAccess';

class App extends Component {
  render() {
    return (
      <Router>
        <AppRouterAccess />
      </Router>
    );
  }
}

export default App;