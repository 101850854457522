import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
// import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
// import Home from './Home';
import Login from './Login';
// import Protected from './pages/MainForm';
// import Training from './pages/TrainingMessage';

export default withRouter(class AppWithRouterAccess extends Component {
  constructor(props) {
    super(props);
    this.onAuthRequired = this.onAuthRequired.bind(this);
  }

  onAuthRequired() {
    this.props.history.push('/login')
  }

  render() {
    const baseUrl = process.env.REACT_APP_OKTA_URL;
    const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;
    const issuer = process.env.REACT_APP_OKTA_ISSUER;
    // console.log('OKTA SYSTEM - ' + baseUrl);
    // console.log('OKTA ISSUER - ' + issuer);

    return (
      <Security issuer={baseUrl + issuer}
                clientId={clientId}
                redirectUri={window.location.origin + '/login/callback'}
                onAuthRequired={this.onAuthRequired} >
        <Route path='/' render={() => <Login baseUrl={baseUrl} />} />
        {/* <SecureRoute path='/form' component={Protected} /> */}
        <Route path='/login/callback' component={LoginCallback} />
      </Security>
    );
  }
});