import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

class Page extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.querySelectorAll('header p').forEach((e) => {
      e.previousElementSibling.style.marginBottom = '0';
      e.classList.add('hidden');
    });

    document.querySelector('.form').classList.add('submitted');
    // console.log(this.props);
  }

  restartForm = (e) => {
    e.preventDefault();
    const reentryapi = process.env.REACT_APP_REENTRY_API;
    let lastsubId = this.props.data.lastsub.id || this.props.data.lastsub.data.id;

    const cookie = new Cookies();
    cookie.remove('usahsReentry-access');
    cookie.remove('usahsReentry-qrcode');

    axios.post(reentryapi+'/form/delete/'+lastsubId)
    .then(function (response) {
      // console.log(response);
      window.location = "/";
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    // console.dir(this.props);

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let mm = today.getMonth();
    let yyyy = today.getFullYear();
    today = month[mm] + ' ' + dd + ', ' + yyyy;

    return (
      <>
        <div className="form">
          <div className="container">
            <div className="row">
              <div className="col-6 col-offset-3">

                <h2><i className="fa fa-times-circle red"></i> Thank You.</h2>
                <p>Thank you for submitting your daily health screening form for today, <strong className="notcleared">{ today }</strong>.</p>

                <p>Based on your health screening results for today, you are <strong className="notcleared">NOT cleared to come to campus</strong>.</p>

                <p>You will be receiving a confirmation email with further instructions from <a href="mailto:usahsadmin@usa.edu">usahsadmin@usa.edu</a>. If you do not receive the email, please contact <a href="mailto:reentry@usa.edu">reentry@usa.edu</a> or call <strong>1-855-GOUSAHS (1-855-468-7247)</strong>.</p>

                {!this.props.reset && (
                  <>
                  <p className="mistake">If you feel this was a <u>mistake</u>, please restart your Daily Submisison by clicking below.</p>
                  <div className="button-cont"><button type="submit" className="btn btn-primary" onClick={ this.restartForm.bind(this) }>Re-Start Submisison</button></div>
                  </>
                )}

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Page;