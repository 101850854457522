import React, { Component } from 'react';
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components

export class Header extends Component  {
  state = { status: null, training: null }

  componentDidMount() {
    this.setState({
      status: this.props.data.redirect,
      training: this.props.data.training
    })
  }

  render() {
    const { training, status } = this.state;

    return (
      <>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-8 col-offset-2">

                <div className="title">
                  <img src={process.env.PUBLIC_URL + '/global/images/crest512.png'} className="logo" alt="University of St. Augustine" />
                  <h1>USAHS Health<br/> Screening Form</h1>
                </div>

                {(training === '1' || status !== 'null') && (
                  <>
                    <p><strong>The University of St. Augustine for Health Sciences (USAHS) strives to provide a safe and healthy environment for our campus community.</strong>  Completion of the self-reported health screening questions below is required prior to entering campus. You will receive an automated QR Code confirmation and email that will be scanned upon entry to campus, if you are authorized to enter campus. No personal data is required.</p>

                    <p><strong>If you are not authorized to enter, please do not come to campus.</strong> You will receive an automated email notification with further instructions.</p>

                    {/* <p className="notice">Three-ply, surgical/procedural face masks, or N95 / KN95 masks, are required to be worn by <strong>all students, employees and visitors while present in classrooms, labs, pro bono clinics, the Center for Innovative Clinical Practice (CICP)</strong> and during any <strong>activities with patients</strong>. This policy is effective regardless of the number of people in these academic campus rooms or locations. In <strong>all other campus areas</strong> (including hallways, offices/office areas, libraries, lounges and study spaces), masks will be <strong>optional</strong> for all students, faculty and staff.</p> */}

                    {/* <p className="notice">The University of St. Augustine for Health Sciences maintains its health and safety protocols, including quarantine periods, cleaning procedures, and vaccine and testing options.<br/><br/><strong>Effective May 9, 2022</strong>, the use of face masks is <strong>optional in all campus facilities</strong>. We continue to encourage the use of masks for our pro-bono clinics where at-risk patients participate. Please refer to the Campus Reentry page for updated, detailed policies and procedures.</p> */}
                  </>
                )}

              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;